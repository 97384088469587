<template>
  <v-dialog :value="value" persistent width="600">
    <v-card>
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{report.name}}</v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row v-for="parameter in reportParameters" :key="parameter.name" justify="center">
          <InputText v-if="parameter.data_type !== 'dimension'" :parameter="parameter" @add="$emit('add', $event)"/>
          <InputSelect v-else :parameter="parameter" :reportDimensions="reportDimensions" @add="$emit('add', $event)"/>
        </v-row>
      </v-container>
      <v-divider/>
      <v-toolbar flat color="#fafafa">
        <v-spacer/>
        <v-btn tabindex="-1" color="#0c67a5" class="white--text" :loading="loading" @click="$emit('submit')">
          Run Report<v-icon right>mdi-play-circle-outline</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { notification } from '@/mixins/notification'

export default {
  name: 'ReportBuilder',

  components: {
    InputText: () => import('@/components/reporting/input-text'),
    InputSelect: () => import('@/components/reporting/input-select')
  },

  props: ['value', 'report', 'reportParameters', 'reportDimensions'],

  mixins: [notification],

  data () {
    return {
      width: 500,
      loading: false
    }
  },

  computed: {
    dialogSize () {
      if (this.parameters.length > 1) {
        return this.reportParameters.length * 300
      } else {
        return this.reportParameters.length * 400
      }
    }
  },

  methods: {
    // addValues (key, value) {
    //   if (isEmpty(this.parameters)) {
    //     this.parameters[`${key}`] = value
    //   } else {
    //     for (const property in this.parameters) {
    //       if (property === key) {
    //         this.parameters[property] = value
    //       } else {
    //         this.parameters[`${key}`] = value
    //       }
    //     }
    //   }
    // },

    // async submitReport () {
    //   if ('secret' in this.parameters && this.parameters.secret === 'Peter Gibbons') {
    //     var win = window.open('http://d2agz7p8m5udbi.cloudfront.net/', '_blank')
    //     win.focus()
    //   } else if ('secret' in this.parameters && this.parameters.secret !== 'Peter Gibbons') {
    //     this.notify('error', 'Invalid Report Parameters')
    //   } else {
    //     var arr = []
    //     for (const property in this.parameters) {
    //       arr.push(`${property}=${this.parameters[property]}`)
    //     }
    //     this.parameters = arr.join('&')
    //     await reports.submitReport(this.report.id, this.parameters)
    //       .then(() => {
    //         this.$emit('update:value', false)
    //         this.notify('success', 'Report Successfully Submitted')
    //       }).catch(() => {
    //         this.notify('error', 'Invalid Report Parameters')
    //       })
    //   }
    //   this.$emit('refresh-queue')
    // }
  }
}
</script>
