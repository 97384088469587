var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "600" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.report.name))])
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-6" },
            _vm._l(_vm.reportParameters, function(parameter) {
              return _c(
                "v-row",
                { key: parameter.name, attrs: { justify: "center" } },
                [
                  parameter.data_type !== "dimension"
                    ? _c("InputText", {
                        attrs: { parameter: parameter },
                        on: {
                          add: function($event) {
                            return _vm.$emit("add", $event)
                          }
                        }
                      })
                    : _c("InputSelect", {
                        attrs: {
                          parameter: parameter,
                          reportDimensions: _vm.reportDimensions
                        },
                        on: {
                          add: function($event) {
                            return _vm.$emit("add", $event)
                          }
                        }
                      })
                ],
                1
              )
            }),
            1
          ),
          _c("v-divider"),
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "#fafafa" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: {
                    tabindex: "-1",
                    color: "#0c67a5",
                    loading: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("submit")
                    }
                  }
                },
                [
                  _vm._v(" Run Report"),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-play-circle-outline")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }